"use strict";

var FIELDS = ["name", "email", "phone", "message"];

var send = function send(payload) {
    console.log(payload);
};

window.addEventListener("DOMContentLoaded", function () {
    var form = document.querySelector("form.contact-form");
    if (form) {
        form.addEventListener("submit", function (e) {
            e.preventDefault();

            // ==> Genereting payload
            var payload = {};
            FIELDS.map(function (each) {
                var _document$querySelect = document.querySelector("input[name=" + each + "]"),
                    value = _document$querySelect.value;

                payload[each] = value;
            });

            // ==> Sending message
            sned(payload);
        });
    }
});