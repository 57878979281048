"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/*! modernizr 3.5.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-prefixedcss-prefixedcssvalue-setclasses-teststyles !*/
!function (e, n, t) {
  function r(e, n) {
    return (typeof e === "undefined" ? "undefined" : _typeof(e)) === n;
  }function o() {
    var e, n, t, o, i, s, a;for (var l in C) {
      if (C.hasOwnProperty(l)) {
        if (e = [], n = C[l], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length)) for (t = 0; t < n.options.aliases.length; t++) {
          e.push(n.options.aliases[t].toLowerCase());
        }for (o = r(n.fn, "function") ? n.fn() : n.fn, i = 0; i < e.length; i++) {
          s = e[i], a = s.split("."), 1 === a.length ? Modernizr[a[0]] = o : (!Modernizr[a[0]] || Modernizr[a[0]] instanceof Boolean || (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])), Modernizr[a[0]][a[1]] = o), h.push((o ? "" : "no-") + a.join("-"));
        }
      }
    }
  }function i(e) {
    var n = x.className,
        t = Modernizr._config.classPrefix || "";if (_ && (n = n.baseVal), Modernizr._config.enableJSClass) {
      var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");n = n.replace(r, "$1" + t + "js$2");
    }Modernizr._config.enableClasses && (n += " " + t + e.join(" " + t), _ ? x.className.baseVal = n : x.className = n);
  }function s(e) {
    return e.replace(/([A-Z])/g, function (e, n) {
      return "-" + n.toLowerCase();
    }).replace(/^ms-/, "-ms-");
  }function a() {
    return "function" != typeof n.createElement ? n.createElement(arguments[0]) : _ ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0]) : n.createElement.apply(n, arguments);
  }function l(e) {
    return e.replace(/([a-z])-([a-z])/g, function (e, n, t) {
      return n + t.toUpperCase();
    }).replace(/^-/, "");
  }function f(e, n) {
    return !!~("" + e).indexOf(n);
  }function u(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }function p(e, n, t) {
    var o;for (var i in e) {
      if (e[i] in n) return t === !1 ? e[i] : (o = n[e[i]], r(o, "function") ? u(o, t || n) : o);
    }return !1;
  }function c(n, t, r) {
    var o;if ("getComputedStyle" in e) {
      o = getComputedStyle.call(e, n, t);var i = e.console;if (null !== o) r && (o = o.getPropertyValue(r));else if (i) {
        var s = i.error ? "error" : "log";i[s].call(i, "getComputedStyle returning null, its possible modernizr test results are inaccurate");
      }
    } else o = !t && n.currentStyle && n.currentStyle[r];return o;
  }function d() {
    var e = n.body;return e || (e = a(_ ? "svg" : "body"), e.fake = !0), e;
  }function m(e, t, r, o) {
    var i,
        s,
        l,
        f,
        u = "modernizr",
        p = a("div"),
        c = d();if (parseInt(r, 10)) for (; r--;) {
      l = a("div"), l.id = o ? o[r] : u + (r + 1), p.appendChild(l);
    }return i = a("style"), i.type = "text/css", i.id = "s" + u, (c.fake ? c : p).appendChild(i), c.appendChild(p), i.styleSheet ? i.styleSheet.cssText = e : i.appendChild(n.createTextNode(e)), p.id = u, c.fake && (c.style.background = "", c.style.overflow = "hidden", f = x.style.overflow, x.style.overflow = "hidden", x.appendChild(c)), s = t(p, e), c.fake ? (c.parentNode.removeChild(c), x.style.overflow = f, x.offsetHeight) : p.parentNode.removeChild(p), !!s;
  }function v(n, r) {
    var o = n.length;if ("CSS" in e && "supports" in e.CSS) {
      for (; o--;) {
        if (e.CSS.supports(s(n[o]), r)) return !0;
      }return !1;
    }if ("CSSSupportsRule" in e) {
      for (var i = []; o--;) {
        i.push("(" + s(n[o]) + ":" + r + ")");
      }return i = i.join(" or "), m("@supports (" + i + ") { #modernizr { position: absolute; } }", function (e) {
        return "absolute" == c(e, null, "position");
      });
    }return t;
  }function y(e, n, o, i) {
    function s() {
      p && (delete j.style, delete j.modElem);
    }if (i = r(i, "undefined") ? !1 : i, !r(o, "undefined")) {
      var u = v(e, o);if (!r(u, "undefined")) return u;
    }for (var p, c, d, m, y, g = ["modernizr", "tspan", "samp"]; !j.style && g.length;) {
      p = !0, j.modElem = a(g.shift()), j.style = j.modElem.style;
    }for (d = e.length, c = 0; d > c; c++) {
      if (m = e[c], y = j.style[m], f(m, "-") && (m = l(m)), j.style[m] !== t) {
        if (i || r(o, "undefined")) return s(), "pfx" == n ? m : !0;try {
          j.style[m] = o;
        } catch (h) {}if (j.style[m] != y) return s(), "pfx" == n ? m : !0;
      }
    }return s(), !1;
  }function g(e, n, t, o, i) {
    var s = e.charAt(0).toUpperCase() + e.slice(1),
        a = (e + " " + z.join(s + " ") + s).split(" ");return r(n, "string") || r(n, "undefined") ? y(a, n, o, i) : (a = (e + " " + b.join(s + " ") + s).split(" "), p(a, n, t));
  }var h = [],
      C = [],
      S = { _version: "3.5.0", _config: { classPrefix: "", enableClasses: !0, enableJSClass: !0, usePrefixes: !0 }, _q: [], on: function on(e, n) {
      var t = this;setTimeout(function () {
        n(t[e]);
      }, 0);
    }, addTest: function addTest(e, n, t) {
      C.push({ name: e, fn: n, options: t });
    }, addAsyncTest: function addAsyncTest(e) {
      C.push({ name: null, fn: e });
    } },
      Modernizr = function Modernizr() {};Modernizr.prototype = S, Modernizr = new Modernizr();var x = n.documentElement,
      _ = "svg" === x.nodeName.toLowerCase(),
      w = "Moz O ms Webkit",
      b = S._config.usePrefixes ? w.toLowerCase().split(" ") : [];S._domPrefixes = b;var E = function E(e, n) {
    var t = !1,
        r = a("div"),
        o = r.style;if (e in o) {
      var i = b.length;for (o[e] = n, t = o[e]; i-- && !t;) {
        o[e] = "-" + b[i] + "-" + n, t = o[e];
      }
    }return "" === t && (t = !1), t;
  };S.prefixedCSSValue = E;var z = S._config.usePrefixes ? w.split(" ") : [];S._cssomPrefixes = z;var P = function P(n) {
    var r,
        o = prefixes.length,
        i = e.CSSRule;if ("undefined" == typeof i) return t;if (!n) return !1;if (n = n.replace(/^@/, ""), r = n.replace(/-/g, "_").toUpperCase() + "_RULE", r in i) return "@" + n;for (var s = 0; o > s; s++) {
      var a = prefixes[s],
          l = a.toUpperCase() + "_" + r;if (l in i) return "@-" + a.toLowerCase() + "-" + n;
    }return !1;
  };S.atRule = P;var N = { elem: a("modernizr") };Modernizr._q.push(function () {
    delete N.elem;
  });var j = { style: N.elem.style };Modernizr._q.unshift(function () {
    delete j.style;
  }), S.testAllProps = g;var L = S.prefixed = function (e, n, t) {
    return 0 === e.indexOf("@") ? P(e) : (-1 != e.indexOf("-") && (e = l(e)), n ? g(e, n, t) : g(e, "pfx"));
  };S.prefixedCSS = function (e) {
    var n = L(e);return n && s(n);
  }, S.testStyles = m;o(), i(h), delete S.addTest, delete S.addAsyncTest;for (var T = 0; T < Modernizr._q.length; T++) {
    Modernizr._q[T]();
  }e.Modernizr = Modernizr;
}(window, document);