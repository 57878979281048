"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var scroll = function scroll() {
    var NAVBAR_HEIGHT = document.querySelector(".navbar").offsetHeight || 65;

    var _document$querySelect = document.querySelector("[data-scroll-target='true']").getClientRects(),
        _document$querySelect2 = _slicedToArray(_document$querySelect, 1),
        target = _document$querySelect2[0];

    if (target) {
        window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: target.top - NAVBAR_HEIGHT
        });
    }
};

window.addEventListener("DOMContentLoaded", function () {
    var scrollTrigger = document.querySelector(".scroll-indicator-wrapper");
    if (scrollTrigger) {
        scrollTrigger.addEventListener("click", function () {
            return scroll();
        });
    }
});