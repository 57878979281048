"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

window.addEventListener("DOMContentLoaded", function () {
    var _document$getElements = document.getElementsByClassName("navbar-toggler-btn"),
        _document$getElements2 = _slicedToArray(_document$getElements, 1),
        trigger = _document$getElements2[0];

    var _document$getElements3 = document.getElementsByClassName("navbar"),
        _document$getElements4 = _slicedToArray(_document$getElements3, 1),
        navbar = _document$getElements4[0];

    var _document$getElements5 = document.getElementsByClassName("navbar-brand"),
        _document$getElements6 = _slicedToArray(_document$getElements5, 1),
        brand = _document$getElements6[0];

    trigger.addEventListener("click", function () {
        return navbar.classList.toggle("__is-active");
    });

    window.addEventListener("scroll", function () {
        if (window.scrollY <= 10) {
            brand.classList.remove("__out-top");
            navbar.classList.add("__on-top");
        } else {
            brand.classList.add("__out-top");
            navbar.classList.remove("__on-top");
        }
    });
});

/*
* Inactive the active page in navbar itens
 */
var inactive = function inactive() {
    var activeNavItem = document.querySelector(".nav-link.active");
    activeNavItem.classList.remove("active");
};

/*
* Active your actual page in navbar itens
*
* @param target { string } data-target attribute to wanted nav-link
* @returns { boolean } For right execution or not
 */
var active = function active() {
    var target = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "home";

    // Inactive actual active page item
    inactive();

    // ==> Active actual page item
    var navItem = document.querySelector("[data-target=" + target + "]");
    if (navItem) {
        navItem.classList.add("active");
        return true;
    }
    return false;
};